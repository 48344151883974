import ReCAPTCHA from "react-google-recaptcha"
import { useRef, useMemo, useState, useEffect } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { useMediaPredicate } from "react-media-hook"
import { useRouter } from "next/router"
import Image from "next/image"
import dynamic from "next/dynamic"
import Modal from "react-modal"
import classNames from "classnames"
import { usePathData, useAppContext } from "@/lib/contexts/appContext"
import { useMember } from "@/lib/hooks/useMember"
import { pushDataLayer } from "@/lib/helpers/tagManager"
import Storage from "@/lib/storage"
import { useNameCollectionContext } from "@/lib/contexts/nameCollectionContext"
import { createGravityFormEntry } from "@/lib/api/gravityForms"
import { useGravityForm } from "@/lib/hooks/useGravityForm"
import Form from "@/components/gravity-forms/Form"
import Spinner from "@/components/common/logos/spinner"
import Block from "@/components/wrappers/block"
import styles from "./gravity-form.module.css"
import Close from "@/components/common/icons/close"

export default function GravityForm({ block, settings }) {
  const router = useRouter()
  const { path, template } = usePathData()
  const isArticle = template === "article"
  const { setHasSentGravityForm } = useAppContext()
  const nameCollectionContext = useNameCollectionContext()
  const { parentBlock, objectId, inSidebar } = block.attrs
  const form = useGravityForm(block?.attrs?.form)
  const { isLoggedIn } = useMember()

  const methods = useForm({ mode: "onTouched" })
  const { formState, handleSubmit, setError, reset, getValues } = methods

  const [currentPage, setCurrentPage] = useState(1)
  const [hasResultBlock, setResultBlock] = useState(false)
  const [result, setResult] = useState(null)
  const [uploadFiles, setUploadFiles] = useState({})
  const [hasMounted, setMounted] = useState(false)
  const [triggeredStart, setTriggeredStart] = useState(false)
  const [cookieBot, setCookieBot] = useState(null)
  const [userIp, setUserIp] = useState(null)

  const isNarrow = useMediaPredicate("(max-width: 1024px)")

  const defaultModalState = useMemo(() => {
    const data = {}
    if (!form?.fields) {
      return data
    }

    const fieldsWithModal = form.fields.filter((field) => modals[field.type])
    fieldsWithModal.forEach((field) => {
      data[field.id] = { state: false, props: {} }
    })
    return data
  }, [form])

  const [modalState, setModalState] = useState(defaultModalState)

  const toggleModal = (id, open, props = {}) => {
    const state = { ...modalState }
    state[id] = { state: open, props: props }

    setModalState(state)
  }

  const siteKey = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY
  const recaptchaRef = useRef()
  const formRef = useRef()

  const handleCookieBot = () => {
    setCookieBot(window?.Cookiebot?.consent?.statistics || false)
  }

  const onSubmit = async (formData) => {
    formData.objectId = objectId
    formData.parentBlock = parentBlock
    formData.form_unique_id = gformUniqueId
    formData.gform_uploaded_files = uploadFiles
    formData.metaData = {
      postId: objectId,
      path: path,
      isLoggedIn: isLoggedIn,
      cookieBot: cookieBot,
      ip: userIp
    }

    const token = await recaptchaRef.current.executeAsync()
    const response = await createGravityFormEntry(form.id, formData, token)

    if (nameCollectionContext) {
      nameCollectionContext.refreshCount()
    }

    const { is_valid } = response.data

    const header = document.getElementById("header")
    const formTop = formRef.current.offsetTop

    window.scrollTo({
      top: formTop - header.offsetHeight - 30,
      behavior: "smooth"
    })

    if (is_valid) {
      pushDataLayer({
        event: "form_submit",
        formId: form?.id,
        formTitle: form?.title,
        formType: form?.formType,
        formData: null
      })

      // Collapse this component if we have result block on same page
      setResultBlock(response.data.hasResultBlock)
      setHasSentGravityForm(true)

      // Is Quiz i.e has hashes set localStorage
      if (response?.data?.hashes) {
        Storage.set(
          "quiz",
          {
            ...response.data.hashes,
            id: form.id,
            resultPath: response.data.hasResultBlock
              ? window.location.pathname
              : response.data?.confirmation_redirect
          },
          "session"
        )

        // If we need to update resultBlock on same page
        if (
          response.data.hasResultBlock &&
          response.data.confirmation_type !== "redirect"
        ) {
          const url = encodeURI(
            window.location.origin +
              window.location.pathname +
              "?entry=" +
              response.data.hashes.entry
          )
          router.replace(url)
          return
        }
      }

      // Don't repaint this component if we redirect
      if (response.data.confirmation_type === "redirect") {
        router.push(response.data.confirmation_redirect)
        return
      }

      // Let's repaint the component with result data (if not in resultblock)
      setResult(response.data)
      return
    }

    setResult(null)
    for (const key in response.data.validation_messages) {
      setError(`input_${key}`, {
        type: "manual",
        message:
          response.data.validation_messages[key] ??
          "Fel uppstod. Kontrollera detta fält."
      })
    }
  }

  useEffect(() => {
    let isMounted = true
    window.addEventListener("CookiebotOnConsentReady", handleCookieBot)

    if (!block?.attrs?.form.preventIp) {
      getIpAddress().then((ip) => {
        if (isMounted) {
          setUserIp(ip)
        }
      })
    }

    if (isMounted) {
      setMounted(true)
      // check for Quiz on same path
      const quiz = Storage.get("quiz", {}, "session")
      if (
        quiz.id === form?.id &&
        quiz.resultPath === window.location.pathname
      ) {
        const url = encodeURI(
          window.location.origin +
            window.location.pathname +
            "?entry=" +
            quiz.entry
        )
        setResultBlock(true)
        router.replace(url)
      }
    }
    return () => {
      isMounted = false
      window.removeEventListener("CookiebotOnConsentReady", handleCookieBot)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let resetButton = null

    if (
      formState.isDirty &&
      Object.keys(getValues()).length > 0 &&
      !triggeredStart
    ) {
      setTriggeredStart(true)
      pushDataLayer({
        event: "form_start",
        formId: form?.id,
        formTitle: form?.title,
        formType: form?.formType
      })
    }

    if (formState.isSubmitSuccessful) {
      resetButton = document.querySelector(".confirmation button[type=reset]")
      if (resetButton) {
        resetButton.addEventListener("click", handleResetButtonClick)
      }
    }

    return () => {
      resetButton = document.querySelector(".confirmation button[type=reset]")
      if (resetButton) {
        resetButton.removeEventListener("click", handleResetButtonClick)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  const handleResetButtonClick = () => {
    setCurrentPage(1)
    setResult(null)
    setUploadFiles({})
    setTriggeredStart(false)

    const header = document.getElementById("header")
    const formTop = formRef.current.offsetTop
    window.scrollTo({
      top: formTop - header.offsetHeight - 30,
      behavior: "smooth"
    })

    reset()
  }

  if (!form || hasResultBlock) {
    return null
  }

  if (!form.fields || form?.fields?.length === 0) {
    return null
  }

  const { cssClass } = form
  const gformUniqueId = generateUniqueID()
  const hasGrid =
    form?.quiz?.hasImages && !form?.quiz?.multiplePerPage && !isArticle

  const blockClasses = [
    styles.gravityForm,
    cssClass,
    {
      "is-article": isArticle,
      "has-grid": hasGrid,
      "has-content": settings?.hasContent
    }
  ]

  let width = hasGrid || form?.quiz?.multiplePerPage ? "full" : "column"

  if (inSidebar) {
    width = "bleed"
  }

  if (settings?.width) {
    width = settings.width
  }

  if (settings?.width) {
    width = settings.width
  }

  if (form?.offSchedule) {
    if (!form?.message) {
      return null
    }

    return (
      <Block
        width={width}
        block={block}
        blockRef={formRef}
        className={classNames(blockClasses)}>
        <p>{form?.message}</p>
      </Block>
    )
  }

  return (
    <FormProvider {...methods}>
      <Block
        width={width}
        block={block}
        blockRef={formRef}
        className={classNames(blockClasses)}>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <ReCAPTCHA
            className="hidden"
            ref={recaptchaRef}
            size="invisible"
            sitekey={siteKey}
          />
          {!formState.isSubmitSuccessful && (
            <Form
              block={block}
              gformUniqueId={gformUniqueId}
              formRef={formRef}
              form={form}
              inSidebar={inSidebar}
              isArticle={isArticle}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              uploadFiles={uploadFiles}
              setUploadFiles={setUploadFiles}
              toggleModal={toggleModal}
            />
          )}
          {formState.isSubmitSuccessful && (
            <Confirmation result={result} inSidebar={inSidebar} />
          )}
          <Media
            form={form}
            isArticle={isArticle}
            hasMounted={hasMounted}
            isNarrow={isNarrow}
            currentPage={currentPage}
          />
        </form>
      </Block>
      <Modals form={form} modalState={modalState} toggleModal={toggleModal} />
    </FormProvider>
  )
}

const Media = ({ form, isArticle, hasMounted, isNarrow, currentPage }) => {
  if (isArticle || isNarrow || !hasMounted) {
    return null
  }

  if (!form?.quiz?.hasImages || form?.quiz?.multiplePerPage) {
    return null
  }

  const field = form.fields
    .filter((item) => item?.imageData && item.pageNumber === currentPage)
    .shift()

  if (!field?.imageData.url) {
    return null
  }

  return (
    <div
      className={classNames("extra-content", {
        "is-4-3": form?.quiz?.imageFormat === "4:3",
        "is-16-9": form?.quiz?.imageFormat === "16:9",
        "is-square": form?.quiz?.imageFormat === "square"
      })}>
      <figure>
        <div className="spinner">
          <Spinner className="w-8 h-8 opacity-25" />
        </div>
        <Image
          src={field.imageData.url}
          alt={field.imageData.alt}
          objectPosition="center"
          layout="fill"
          objectFit="cover"
          quality="50"
          sizes="(max-width: 768px) 100vw, 50vw"
        />
      </figure>
    </div>
  )
}

export function Modals({ form, modalState, toggleModal }) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      const isOpen = Object.entries(modalState)
        // eslint-disable-next-line
        .filter(([id, data]) => data.state)
        .map(([id]) => id)

      if (event.keyCode === 27 && isOpen.length > 0) {
        isOpen.forEach((field) => {
          toggleModal(field, false)
        })
      }
    }

    window.addEventListener("keyup", handleKeyDown)

    return () => {
      window.removeEventListener("keyup", handleKeyDown)
    }
  }, [modalState, toggleModal])

  return form.fields.map((field) => {
    if (modals[field.type]) {
      Modal.setAppElement("#__next")

      const ModalComponent = modals[field.type]
      const customStyles = {
        overlay: {
          display: "flex",
          backgroundColor: "rgba(0, 0, 0, .5)",
          zIndex: 1000,
          alignItems: "center",
          justifyContent: "center"
        }
      }

      return (
        <Modal
          key={field.id}
          id={"modal-" + field.id}
          isOpen={modalState[field.id]?.state}
          style={customStyles}
          shouldReturnFocusAfterClose={true}
          shouldCloseOnOverlayClick={true}
          className={classNames(styles.modal, styles["modal-" + field.type])}>
          <div
            className={styles.modalButton}
            onClick={() => toggleModal(field.id, false)}>
            <div className="close">
              <Close className="icon" />
            </div>
          </div>
          <ModalComponent
            key={field.id}
            field={field}
            props={modalState[field.id]?.props}
          />
        </Modal>
      )
    }
  })
}

const Confirmation = ({ result, inSidebar }) => {
  const router = useRouter()

  if (result === null) {
    return null
  }

  let html = result.confirmation_message
  const url = encodeURI(window.location.origin + router.asPath)

  html = html.replace(
    "%%facebook%%",
    "https://www.facebook.com/share.php?u=" + url
  )
  html = html.replace(
    "%%twitter%%",
    "https://twitter.com/intent/tweet?url=" + url
  )

  const classes = [
    "bg-white rounded py-6 confirmation",
    { "px-6 md:px-7.5 px-0": inSidebar !== true }
  ]

  return (
    <div
      className={classNames(classes)}
      dangerouslySetInnerHTML={{
        __html: html
      }}></div>
  )
}

export const generateUniqueID = () => {
  return "xxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const modals = {
  tf_vote: dynamic(() => import("@/components/gravity-forms/Modals/Vote"))
}

export const modalStyles = {
  tf_vote: dynamic(() =>
    import("@/components/gravity-forms/Modals/Vote.module.css")
  )
}

const getIpAddress = async () => {
  const response = await fetch("https://api.ipify.org?format=json")
  const data = await response.json()
  return data.ip
}
